<template>
	<StatisticsSums scope="general" />
</template>

<script>
import StatisticsSums from "../../components/statistics/StatisticsSums.vue";

export default {
	components: {
		StatisticsSums,
	},
};
</script>
